const phone_type_enum = {
  Home: 0,
  Office: 1,
  Mobile: 2,
  Fax: 3,
  LineID: 4,
};

const phone_type = [
  { text: "住家", value: phone_type_enum.Home },
  { text: "辦公室", value: phone_type_enum.Office },
  { text: "行動", value: phone_type_enum.Mobile },
  { text: "傳真", value: phone_type_enum.Fax },
  { text: "LineID", value: phone_type_enum.LineID },
];

const address_type_enum = {
  Home: 0,
  Office: 1,
};

const address_type = [
  { text: "Home", value: address_type_enum.Home },
  { text: "Office", value: address_type_enum.Office },
];

const verifying_enum = {
  Waiting: 0,
  Verifying: 1,
  Done: 2,
  Fail: 3,
};

const setting_type_enum = {
  Private: 0,
  Construction: 1,
  Other: 2, //民間
  Bank: 3, // 銀行
};
const setting_type = [
  { text: "自然人", value: setting_type_enum.Private },
  { text: "建設公司", value: setting_type_enum.Construction },
  { text: "民間", value: setting_type_enum.Other },
  { text: "銀行", value: setting_type_enum.Bank },
];

const payment_enum = {
  CreditorCard: 0,
  Point: 1,
  ATM: 2,
};

const payment_enum_str = [
  { text: "信用卡", value: payment_enum.CreditorCard },
  { text: "點數", value: payment_enum.Point },
  { text: "ATM", value: payment_enum.ATM },
];

const point_type_enum = {
  Point: 0,
  Bonus: 1,
};

const point_type_enum_str = [
  { text: "點數", value: point_type_enum.Point },
  { text: "回饋", value: point_type_enum.Bonus },
];

const shop_type_enum = {
  Unknown: -1,
  Information: 0,
  Points: 1,
  Stock: 2,
};

const shop_type_enum_str = [
  { text: "未知", value: shop_type_enum.Unknown },
  { text: "情資", value: shop_type_enum.Information },
  { text: "點數", value: shop_type_enum.Points },
  { text: "庫存", value: shop_type_enum.Stock },
];

const history_type_enum = {
  All: 0,
  Recharge: 1,
  Shop: 2,
  PointUse: 3,
};
const history_type_enum_str = [
  { text: "全部", value: history_type_enum.All },
  { text: "充值", value: history_type_enum.Recharge },
  { text: "商店", value: history_type_enum.Shop },
  { text: "點數使用", value: history_type_enum.PointUse },
];

const point_status_enum = {
  Valid: 0,
  Invalid: 1,
  Overdue: 2,
  RunOut: 3,
};

const point_status_enum_str = [
  { text: "有效", value: point_status_enum.Valid },
  { text: "無效", value: point_status_enum.Invalid },
  { text: "過期", value: point_status_enum.Overdue },
  { text: "已使用", value: point_status_enum.RunOut },
];

const point_transaction_type_enum = {
  Add: 0,
  Remove: 1,
};

const point_transaction_type_enum_str = [
  { text: "新增", value: point_transaction_type_enum.Add },
  { text: "使用", value: point_transaction_type_enum.Remove },
];

const shop_status_enum = {
  All: -1,
  Init: 0,
  Paying: 1,
  Paid: 2,
  Processing: 3,
  Done: 5,
  Error: 10,
};

const shop_status_enum_str = [
  { text: "建立", value: shop_status_enum.Init },
  { text: "等待支付", value: shop_status_enum.Paying },
  { text: "付款", value: shop_status_enum.Paid },
  { text: "處理中", value: shop_status_enum.Processing },
  { text: "完成", value: shop_status_enum.Done },
  { text: "錯誤", value: shop_status_enum.Error },
];

const shop_status_enum_info = [
  { text: "建立", value: shop_status_enum.Init, class: "bg-gradient-light" },
  {
    text: "等待支付",
    value: shop_status_enum.Paying,
    class: "bg-gradient-light",
  },
  { text: "付款", value: shop_status_enum.Paid, class: "bg-gradient-info" },
  {
    text: "處理中",
    value: shop_status_enum.Processing,
    class: "bg-gradient-success",
  },
  { text: "完成", value: shop_status_enum.Done, class: "bg-gradient-primary" },
  { text: "錯誤", value: shop_status_enum.Error, class: "bg-gradient-danger" },
];

const schedule_status_enum = {
  Any: 99999,
  Unknown: -1,
  Init: 0,
  Ongoing: 1,
  Done: 2,
  Fail: 3,
  Suspend: 4,
};

const schedule_status_enum_str = [
  { text: "全部", value: schedule_status_enum.Any },
  { text: "未知", value: schedule_status_enum.Unknown },
  { text: "建立", value: schedule_status_enum.Init, class: "bg-gradient-info" },
  {
    text: "執行",
    value: schedule_status_enum.Ongoing,
    class: "bg-gradient-success",
  },
  {
    text: "完成",
    value: schedule_status_enum.Done,
    class: "bg-gradient-primary",
  },
  {
    text: "錯誤",
    value: schedule_status_enum.Fail,
    class: "bg-gradient-danger",
  },
  {
    text: "暫停",
    value: schedule_status_enum.Suspend,
    class: "bg-gradient-danger",
  },
];

const schedule_type_enum = {
  PrivateDetect: 0,
  QueryOnly: 2
};

const schedule_type_enum_str = [
  { text: "私設", value: schedule_type_enum.PrivateDetect },
  { text: "查詢", value: schedule_type_enum.QueryOnly }
];
const transaction_type_enum = {
  Land: 0,
  Building: 1,
};

const transaction_type_enum_str = [
  { text: "土地", value: transaction_type_enum.Land },
  { text: "建物", value: transaction_type_enum.Building },
];


const motor_parking_status_enum = {
  No: 0,
  Convenient: 1,
  Rent: 2,
  Yes: 3,
};

const motor_parking_status_enum_str = [
  { text: "無", value: motor_parking_status_enum.No },
  { text: "有", value: motor_parking_status_enum.Yes },
  { text: "方便", value: motor_parking_status_enum.Convenient },
  { text: "可租", value: motor_parking_status_enum.Rent },
];

// temp
const customer_land_mission = [
  "02_高速鐵路桃園車站特定區",
  "03_小檜溪重劃區",
  "04_機場捷運A7站地區(第一期)",
  "05_桃園中路計畫區",
  "06_桃園A10計畫區",
  "07_桃園航空城附近地區第二",
  "08_台中14期",
  "09_水湳機場原址整體開發區",
  "10_G12、G13、G13a整體開發區（新）",
  "11_臺鐵都會區捷運化桃園段地下化建設計畫",
  "12_機場捷運A20站地區",
  "13_楊梅幼獅工業區擴大【第二期】",
  "14_桃園航空城客運園區",
  "15_蘆洲北側_匯出",
  "16_草漯第一區整體開發單元",
  "17_草漯第三區整體開發單元",
  "18_草漯第六區整體開發單元",
  "19_變更觀音(新坡地區)都市計畫案_農業區專案",
];

const customer_building_mission = ["01_高速鐵路桃園車站特定區(建物)"];


const customer_type_enum = {
  unknown: 0,
  self_occupation: 1,
  investors: 2,
};
const customer_type_enum_str = [
  { text: "未知", value: customer_type_enum.unknown },
  { text: "自住客", value: customer_type_enum.self_occupation },
  { text: "投資客", value: customer_type_enum.investors },
];

const buy_steps_enum = {
  Init: 1,
  Summary: 3,
};

// class EZInvoiceStatus(IntEnum):
//     Init = 0
//     Invoice = 1
//     Void = 2
const ez_invoice_status_enum = {
  Unknown: -1,
  Init: 0,
  Invoice: 1,
  Void: 2,
};

const ez_invoice_status_enum_str = [
  { text: "未知", value: ez_invoice_status_enum.Unknown },
  { text: "待開立", value: ez_invoice_status_enum.Init },
  { text: "開立", value: ez_invoice_status_enum.Invoice },
  { text: "作廢", value: ez_invoice_status_enum.Void },
];

const edit_mode_enum = {
  Hidden: 0,
  View: 1,
  Create: 2,
  Edit: 3,

  Inactive: 888,
  Active: 999,
};

const edit_mode_enum_str = [
  { text: "隱藏", value: edit_mode_enum.Hidden },
  { text: "檢視", value: edit_mode_enum.View },
  { text: "新增", value: edit_mode_enum.Create },
  { text: "編輯", value: edit_mode_enum.Edit },
];

const role_enum_str = [
  { "value": "admin", "text": "系統管理員" },
  { "value": "manager", "text": "總經理" },
  { "value": "vice_manager", "text": "副理" },
  { "value": "director", "text": "經理" },
  { "value": "secretary", "text": "秘書" },
  { "value": "assistant", "text": "助理" },
  { "value": "company_sale", "text": "公司業務" },
  { "value": "outside_sale", "text": "外部業務" },
];

const common_text_edit_class = "input-style font-size-input border border-radius-md mt-2"

// UNPUBLISHED = 0
// PUBLISHED = 1
// OFF_SHELF = 2
// INVALID = 3
const bulletin_status_enum = {
  Unpublished: 0,
  Published: 1,
  OffShelf: 2,
  Invalid: 3,
};

const bulletin_status_enum_str = [
  { text: "未發佈", value: bulletin_status_enum.Unpublished },
  { text: "已發佈", value: bulletin_status_enum.Published },
  { text: "已下架", value: bulletin_status_enum.OffShelf },
  { text: "已作廢", value: bulletin_status_enum.Invalid },
];

// class BulletinType(IntEnum):
//     # 系統公告 公司公告
//     SYSTEM = 0
//     COMPANY = 1
const bulletin_type_enum = {
  System: 0,
  Company: 1,
};
const bulletin_type_enum_str = [
  { text: "系統公告", value: bulletin_type_enum.System },
  { text: "公司公告", value: bulletin_type_enum.Company },
];

const import_option_enum = {
  Excel: 0,
  Csv: 1,
  Image: 2,
};

const import_option_enum_str = [
  { text: "Excel", value: import_option_enum.Excel },
  // { text: "Csv", value: import_option_enum.Csv },
  { text: "Image", value: import_option_enum.Image },
];

const image_source_enum = {
  Lion: 0,
};

const image_source_enum_str = [
  { text: "桃園蘋果獅子會", value: image_source_enum.Lion },
];

const ocr_status_enum = {
  Upload: 0,
  Processing: 1,
  Done: 2,
  FailUpload: 8,
  NoQuota: 11,
};

const ocr_status_enum_str = [
  { text: "上傳", value: ocr_status_enum.Upload },
  { text: "處理中", value: ocr_status_enum.Processing },
  { text: "完成", value: ocr_status_enum.Done },
  { text: "上傳失敗", value: ocr_status_enum.FailUpload },
  { text: "無額度", value: ocr_status_enum.NoQuota },
];

const data_source_enum_str = [
  { text: "全部", value: "" },
  { text: "委託", value: "委託" },
  { text: "公開", value: "公開" },
  { text: "中人", value: "中人" },
  { text: "指定", value: "指定" },
  // 謄本
  { text: "謄本", value: "謄本" },
];

const customer_edit_data_source_enum_str = [
  { text: "公開", value: "公開" },
  { text: "指定", value: "指定" },
];

const ImportState = {
  Init: 0,
  Parsing: 1,
  Parsed: 2,
  Paid: 3,
  Processing: 4,
  Completed: 5,
  Importing: 6,
  Failed: -1,
};

const TaskStatus = {
  Init: 0,
  Running: 1,
  Success: 2,
  Failed: 3,
};

const TranscriptType = {
  Telex: 0,
  Class_1: 1,
  Class_2: 2,
  Class_3: 3,
};

const SocketEventType = {
  VoteRecord: 1,
  SignInRecord: 2,
  TranscriptParseStart: 3,
  TranscriptParseEvent: 4,
  TranscriptParseEnd: 5,
};

const change_sale_behavior = {
  ChangeSale: 0,
  Publish: 1,
};

export {
  phone_type_enum,
  phone_type,
  address_type_enum,
  address_type,
  verifying_enum,
  setting_type_enum,
  setting_type,
  payment_enum,
  payment_enum_str,
  point_type_enum,
  point_type_enum_str,
  shop_type_enum,
  shop_type_enum_str,
  history_type_enum,
  history_type_enum_str,
  point_status_enum,
  point_status_enum_str,
  point_transaction_type_enum,
  point_transaction_type_enum_str,
  shop_status_enum,
  shop_status_enum_str,
  shop_status_enum_info,
  transaction_type_enum,
  transaction_type_enum_str,
  customer_land_mission,
  customer_building_mission,
  customer_type_enum,
  customer_type_enum_str,
  schedule_status_enum,
  schedule_status_enum_str,
  buy_steps_enum,
  ez_invoice_status_enum,
  ez_invoice_status_enum_str,
  schedule_type_enum,
  schedule_type_enum_str,
  edit_mode_enum,
  edit_mode_enum_str,
  role_enum_str,
  common_text_edit_class,
  bulletin_status_enum,
  bulletin_status_enum_str,
  bulletin_type_enum,
  bulletin_type_enum_str,
  import_option_enum,
  import_option_enum_str,
  image_source_enum,
  image_source_enum_str,
  ocr_status_enum,
  ocr_status_enum_str,
  data_source_enum_str,
  ImportState,
  TaskStatus,
  motor_parking_status_enum_str,
  TranscriptType,
  SocketEventType,
  customer_edit_data_source_enum_str,
  change_sale_behavior,
};
